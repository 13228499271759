/* eslint-disable global-require */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
    Card,
    Icon,
    Button,
    Loader,
    Dimmer,
    Placeholder,
} from 'semantic-ui-react';
import Moment from 'moment';
import { Profile } from './models/Profile';

type Props = {
    isEditing: boolean,
    isLoading: boolean;
    onEditProfile: Function;
    profileData: Profile;
    uploadPhoto: React.MouseEventHandler<HTMLImageElement>;
}

const ProfileDetail: React.FunctionComponent<Props> = ({
    isLoading,
    profileData,
    onEditProfile,
    uploadPhoto,
}) => (
    <Card>
        <Dimmer active={isLoading}>
            <Loader />
        </Dimmer>
        <img
            alt="user"
            src={profileData.picUrl || 'default-user-img.jpg'}
            className="ui image"
            onClick={uploadPhoto}
        />
        <Card.Content>
            {
                profileData.isLoaded
                    ? [
                        <Card.Header key="header">{profileData.name}</Card.Header>,
                        <Card.Meta key="meta">{profileData.location}</Card.Meta>,
                        <Card.Description key="desc">{profileData.description}</Card.Description>,
                    ]
                    : (
                        <Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line length="very short" />
                                <Placeholder.Line length="medium" />
                            </Placeholder.Header>
                            <Placeholder.Paragraph>
                                <Placeholder.Line length="short" />
                            </Placeholder.Paragraph>
                        </Placeholder>
                    )
            }
        </Card.Content>
        <Card.Content extra>
            <Icon name="user" />
            {' '}
            Joined&nbsp;
            {Moment(profileData.created).format('MMMM YYYY')}
        </Card.Content>
        <Button attached="bottom" onClick={() => onEditProfile(true)}>
            <Icon name="pencil" />
            Edit
        </Button>
    </Card>
);

export default ProfileDetail;
