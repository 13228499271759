import React from 'react';
import './Profile.css';
import {
    Transition,
    Segment,
} from 'semantic-ui-react';
import ProfileDetail from './ProfileDetail';
import ProfileEdit from './ProfileEdit';
import StoicPlaceholder from '../Placeholder/Placeholder';
import { Profile as ProfileData, ProfileUpdate } from './models/Profile';

type MyProps = {
    uid: string;
    profileData: ProfileData;
    onSaveProfile: (updateData:ProfileUpdate) => void;
    onSavePhoto: (uid:string, file:File) => void;
    isLoading: boolean;
    isEditing: boolean;
    onEdit:(mode:string)=> void;
}

type MyState = {
    changedAttributes:ProfileData,
};

class Profile extends React.Component<MyProps, MyState> {
    // eslint-disable-next-line react/state-in-constructor
    state: MyState = {
        changedAttributes: {},
    }

    uploadPhoto = () => {
        const uploaderInput = document.getElementById('fileUploader');
        if (uploaderInput) {
            uploaderInput.click();
        }
    }

    editProfile = (toggle:string) => {
        const { onEdit } = this.props;
        onEdit(toggle);
    }

    saveProfile = () => {
        const { uid, onSaveProfile } = this.props;
        const { changedAttributes } = this.state;
        onSaveProfile({
            uid,
            changedAttributes,
        });
    }

    handleUpload = (e: React.ChangeEvent<HTMLInputElement>):void => {
        e.preventDefault();
        const { onSavePhoto, uid } = this.props;
        const uploaderInput = e.target;
        if (uploaderInput && uploaderInput.files) {
            const file = uploaderInput.files[0];
            if (!file) return;
            onSavePhoto(uid, file);
        }
    }

    changeText = (e: React.ChangeEvent<HTMLInputElement>):void => {
        const { changedAttributes } = this.state;
        this.setState({
            changedAttributes: {
                ...changedAttributes,
                [e.target.name]: e.target.value,
            },
        });
    }

    render() {
        const { profileData, isLoading, isEditing } = this.props;
        return (
            <div className="ui container">
                <div className="ui two column centered grid">
                    <div className="row">
                        <div id="profileInfo" className="five wide column">
                            <Transition
                                visible={isEditing === true}
                                animation="horizontal flip"
                                duration={isEditing ? 500 : 0}
                            >
                                <div>
                                    <ProfileEdit
                                        onChangeText={this.changeText}
                                        onSaveProfile={this.saveProfile}
                                        profileData={profileData}
                                    />
                                </div>
                            </Transition>
                            <Transition
                                visible={isEditing === false}
                                animation="horizontal flip"
                                duration={isEditing ? 0 : 500}
                            >
                                <div>
                                    <ProfileDetail
                                        isEditing={isEditing}
                                        isLoading={isLoading}
                                        profileData={profileData}
                                        onEditProfile={this.editProfile}
                                        uploadPhoto={this.uploadPhoto}
                                    />
                                </div>
                            </Transition>
                            <input id="fileUploader" type="file" style={{ display: 'none' }} onChange={this.handleUpload} />
                        </div>
                        <div id="profileData" className="eleven wide column">
                            <StoicPlaceholder />
                            <Segment>
                                Active Cases
                            </Segment>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;
