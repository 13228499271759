import React from 'react';
import {
    Icon,
    Card,
    Button,
    Label,
    CardContent,
} from 'semantic-ui-react';
import DashboardFile from './DashboardFile';

function DashboardCard({
    dashItem, dashId, onDeleteItem, onMarkAsDone, onCopyMove, onEdit,
}) {
    return (
        <>
            <Card key={dashId}>
                <Card.Header>
                    <div style={{ padding: '7px' }}>
                        <h3>
                            {dashItem.title}
                                            &nbsp;
                            {
                                dashItem.isDone && (
                                    <Label size="tiny" color="green">
                                        <Icon name="check circle outline" />
                                        Done
                                    </Label>
                                )
                            }
                        </h3>
                    </div>
                </Card.Header>
                <CardContent>
                    {
                        Object.keys(dashItem.files || {}).map((k) => (
                            <DashboardFile
                                key={dashId}
                                file={dashItem.files[k]}
                            />
                        ))
                    }
                    {
                        Object.keys(dashItem.files || {}).length === 0
                                        && <Card.Content description={dashItem.details} />
                    }
                </CardContent>
                <Card.Content extra>
                    <div className="ui four basic buttons">
                        <Button icon data={dashId} onClick={onEdit}>
                            <Icon color="blue" name="pencil" />
                        </Button>
                        <Button icon data={dashId} onClick={onMarkAsDone}>
                            <Icon color="green" name="check circle outline" />
                        </Button>
                        <Button icon data={dashId} onClick={onCopyMove}>
                            <Icon color="yellow" name="copy outline" />
                        </Button>
                        <Button
                            data={dashId}
                            icon
                            onClick={onDeleteItem}
                        >
                            <Icon color="red" name="times" />
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        </>
    );
}

export default DashboardCard;
