import React, { useState } from 'react';
import {
    Modal,
    Button,
    Form,
    Icon,
} from 'semantic-ui-react';

import DocumentItem from './DocumentItem';

function DocumentModal({ open, setOpen, onSave }) {
    const defaults = {
        itemType: 'no',
        title: '',
        details: '',
        itemFiles: [],
    };

    const [itemDetails, setItemDetails] = useState({ ...defaults });

    const handleChange = (e, { value }) => setItemDetails({
        ...itemDetails,
        itemType: value,
    });

    const addFiles = (files) => setItemDetails({
        ...itemDetails,
        itemFiles: files,
    });

    const onSaveItem = () => {
        onSave(itemDetails);
        setOpen(false);
        setItemDetails({ ...defaults });
    };

    const detailsChange = (details) => {
        setItemDetails({
            ...itemDetails,
            details,
        });
    };

    const titleChange = ({ currentTarget }) => {
        setItemDetails({
            ...itemDetails,
            title: currentTarget.value,
        });
    };

    return (
        <Modal open={open}>
            <Modal.Header>
                <Icon name="plus square outline" />
                New Item
            </Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="Title"
                                placeholder="Title..."
                                onChange={titleChange}
                            />
                        </Form.Group>
                        <Form.Group inline>
                            <label>Type</label>
                            <Form.Radio
                                label="Note"
                                value="no"
                                checked={itemDetails.itemType === 'no'}
                                onChange={handleChange}
                            />
                            <Form.Radio
                                label="Url"
                                value="ur"
                                checked={itemDetails.itemType === 'ur'}
                                onChange={handleChange}
                            />
                            <Form.Radio
                                label="Document/Image"
                                value="im"
                                checked={itemDetails.itemType === 'im'}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <DocumentItem
                            itemType={itemDetails.itemType}
                            onChange={detailsChange}
                            addFiles={addFiles}
                        />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Create"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={onSaveItem}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
}

export default DocumentModal;
