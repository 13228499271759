import React from 'react';
import {
    Message, Icon, Grid,
} from 'semantic-ui-react';
import ConfirmDialog from './Confirm';

function CaseHeader({
    id, details, onCaseClose,
}) {
    const handleCaseClose = () => onCaseClose(id);
    if (!id || !details) {
        return null;
    }
    return (
        <Message style={{ padding: '0px 10px' }} error={details.status === 'closed'}>
            <Grid>
                <Grid.Column width={14}>
                    <Message.Header>
                        <Icon name="info circle" />
                        Case: &nbsp;
                        {details.title}
                    </Message.Header>
                    <small>
                        Case opened:
                        {' '}
                        {new Date(details.caseOpened).toDateString()}
                    </small>
                    {
                        details.lastUpdated && (
                            <>
                                <br />
                                <small>
                                    Last Updated:
                                    {' '}
                                    {new Date(details.lastUpdated).toDateString()}
                                </small>
                            </>
                        )
                    }
                    {
                        details.status && (
                            <>
                                <br />
                                <small>
                                    Status:
                                    {' '}
                                    <b>{details.status}</b>
                                </small>
                            </>
                        )
                    }
                </Grid.Column>
                <Grid.Column width={2}>
                    {
                        details.status !== 'closed'
                        && (
                            <ConfirmDialog
                                onConfirm={handleCaseClose}
                                message="Are you sure you want to close this case?"
                            >
                                Close
                            </ConfirmDialog>
                        )
                    }
                </Grid.Column>
            </Grid>
        </Message>
    );
}

export default CaseHeader;
