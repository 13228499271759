import { all, put, takeLatest } from 'redux-saga/effects';
import firebase from 'firebase';
import { TYPES } from './registerActions';

export function* signUp({ args }) {
    try {
        const {
            username, email, password, passwordConfirm, callback,
        } = args;
        if (password === passwordConfirm) {
            // create the account
            const result = yield firebase.auth().createUserWithEmailAndPassword(email, password);
            const { user } = result;

            // add the user to the database
            yield firebase.database().ref('users').child(user.uid).update({ name: username, created: Date.now() });

            // send email verification
            yield firebase.auth().currentUser.sendEmailVerification();

            yield put({ type: TYPES.SIGN_UP_SUCCESS });

            if (callback) {
                callback();
            }
        } else {
            yield put({ type: TYPES.SIGN_UP_ERROR, error: 'Passwords do not match.' });
        }
    } catch (e) {
        yield put({ type: TYPES.SIGN_UP_ERROR, error: e });
    }
}

export default function* saga() {
    yield all([
        takeLatest(TYPES.SIGN_UP, signUp),
    ]);
}
