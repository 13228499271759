import React from 'react';
import {
    Container,
    Menu,
    Dropdown,
} from 'semantic-ui-react';
import './Header.css';
import firebase from 'firebase/app';
import { Link } from 'react-router-dom';

const Header = ({ profileData }) => {
    const logout = () => firebase.auth().signOut();
    return (
        <header data-test="page-header" className="page-header">
            <Container>
                <Menu className="page-header-menu" secondary>
                    <Menu.Item header><h2>arvada.io</h2></Menu.Item>
                    {
                        !profileData.isEmpty && (
                            <Menu.Menu position="right">
                                <Dropdown style={{ color: 'white' }} item text={profileData.name}>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as={Link} to="/dashboard">
                                            Dashboard
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/archives">
                                            Archives
                                        </Dropdown.Item>
                                        <Dropdown.Item as={Link} to="/">
                                            Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        )
                    }
                </Menu>
            </Container>
        </header>
    );
};

export default Header;
