/* eslint-disable max-len */
import React, { useCallback } from 'react';
import { ButtonGroup, Button, Icon } from 'semantic-ui-react';
import 'react-smart-data-table/dist/react-smart-data-table.css';

type Props = {
    onRowClick: (action:string, row:any) => void;
    row: any;
    value: any;
    index: number;
}

const ArchiveActions:React.FunctionComponent<Props> = ({
    onRowClick, row,
}) => {
    const OnItemAction = useCallback(({ currentTarget }) => {
        const action = currentTarget.getAttribute('data-action');
        onRowClick(action, row);
    }, [row]);

    return (
        <ButtonGroup basic icon>
            <Button
                data-action="open"
                onClick={OnItemAction}
            >
                <Icon color="blue" name="folder open outline" />
            </Button>
            <Button
                data-action="close"
                onClick={OnItemAction}
            >
                <Icon color="green" name="folder outline" />
            </Button>
            <Button
                data-action="remove"
                onClick={OnItemAction}
            >
                <Icon color="red" name="remove" />
            </Button>
        </ButtonGroup>
    );
};

export default ArchiveActions;
