import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import profileReducer from './components/Profile/profileReducer';
import registerReducer from './components/Register/registerReducer';
import loginReducer from './components/Login/loginReducer';
import dashboardReducer from './components/Dashboard/dashboardReducer';

export default combineReducers({
    firebase: firebaseReducer,
    profile: profileReducer,
    register: registerReducer,
    login: loginReducer,
    dashboard: dashboardReducer,
});
