import React, { useState } from 'react';
import {
    Modal,
    Button,
    Form,
} from 'semantic-ui-react';

function CaseModal({ open, setOpen, onSave }) {
    const defaults = {
        title: '',
    };

    const [itemDetails, setItemDetails] = useState({ ...defaults });

    const onSaveItem = () => {
        onSave(itemDetails);
        setOpen(false);
        setItemDetails({ ...defaults });
    };

    const titleChange = ({ currentTarget }) => {
        setItemDetails({
            ...itemDetails,
            title: currentTarget.value,
        });
    };

    return (
        <Modal open={open}>
            <Modal.Header>New Case</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="Title"
                                placeholder="Title..."
                                onChange={titleChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    content="Create"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={onSaveItem}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
}

export default CaseModal;
