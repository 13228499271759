/* eslint-disable no-param-reassign */
import { compose } from 'redux';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import * as actions from './dashboardActions';
import { getUserProfile } from '../../rootSelector';

const mapStateToProps = (state, { match: { params: { id } } }) => {
    const profile = getUserProfile(state);
    const dashItems = state?.dashboard?.items;
    const cases = state?.dashboard?.cases;
    const openCases = Object.keys(cases || {}).reduce((accum, next) => {
        if (cases[next].status === 'open') {
            accum[next] = cases[next];
        }
        return accum;
    }, {});
    return {
        profileData: profile,
        dashItems,
        cases: openCases,
        key: id,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onSaveItem: (args) => dispatch(actions.saveItem(args)),
    onEditSaveItem: (args) => dispatch(actions.editSaveItem(args)),
    onSaveCase: (args) => dispatch(actions.saveCase(args)),
    onCloseCase: (args) => dispatch(actions.closeCase(args)),
    onLoadAll: (args) => dispatch(actions.loadAll(args)),
    onMarkAsDone: (args) => dispatch(actions.markAsComplete(args)),
    onDeleteItem: (args) => dispatch(actions.deleteItem(args)),
    onCopyMoveSave: (args) => dispatch(actions.copyMove(args)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Dashboard);
