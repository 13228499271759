import React, { useState } from 'react';
import {
    Modal,
    Button,
    Form,
    Radio,
} from 'semantic-ui-react';

function CopyMoveModal({
    open, setOpen, cases, onSave, itemId,
}) {
    const [val, handleChange] = useState('Copy');
    const [caseId, handleCaseIdChange] = useState(cases[0]?.key);

    const handleSave = () => {
        onSave(itemId, caseId, val);
    };

    const handleCaseChange = (e, { value }) => {
        handleCaseIdChange(value);
    };

    return (
        <Modal open={open}>
            <Modal.Header>Move/Copy Item</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Form.Select
                                    fluid
                                    label="Case"
                                    placeholder="Select destintation for item..."
                                    options={cases}
                                    onChange={handleCaseChange}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                Operation:
                                {' '}
                                <b>{val}</b>
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                    label="Copy Item"
                                    name="radioGroup"
                                    value="Copy"
                                    checked={val === 'Copy'}
                                    onChange={() => handleChange('Copy')}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Radio
                                    label="Move Item"
                                    name="radioGroup"
                                    value="Move"
                                    checked={val === 'Move'}
                                    onChange={() => handleChange('Move')}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color="black" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    labelPosition="right"
                    content="Save"
                    positive
                    color="black"
                    onClick={handleSave}
                    icon="checkmark"
                />
            </Modal.Actions>
        </Modal>
    );
}

export default CopyMoveModal;
