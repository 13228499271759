/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import {
    Dropdown,
    Menu,
    Icon,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

function DashboardMenu({
    addCase,
    addOne,
    cases,
    onFilter
}) {
    const history = useHistory();
    const onCaseSelected = ({ currentTarget }) => {
        history.push(`/dashboard/${currentTarget.getAttribute('data-key')}`);
    };
    const goToMainDashboard = () => history.push('/dashboard');
    
    const searchTextChange = ({ target: { value }}) => onFilter(value);
    
    return (
        <Menu attached="top">
            <Dropdown item icon="add" simple>
                <Dropdown.Menu>
                    <Dropdown.Item>
                        <Icon name="dropdown" />
                        <span className="text">New</span>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={addOne}>
                                <Icon name="file outline" />
                                Item
                            </Dropdown.Item>
                            <Dropdown.Item onClick={addCase}>
                                <Icon name="folder outline" />
                                Case
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Menu.Item
                icon
                onClick={goToMainDashboard}
            >
                <Icon name="home" />
            </Menu.Item>
            { Object.keys(cases || {}).map((caseKey) => (
                <Menu.Item
                    key={caseKey}
                    data-key={caseKey}
                    name={cases[caseKey].title}
                    onClick={onCaseSelected}
                />
            ))}
            <Menu.Menu position="right">
                <div className="ui right aligned category search item">
                    <div className="ui transparent icon input">
                        <input
                            className="prompt"
                            type="text"
                            placeholder="Search..."
                            onChange={searchTextChange}
                        />
                        <i className="search link icon" />
                    </div>
                    <div className="results" />
                </div>
            </Menu.Menu>
        </Menu>
    );
}

export default DashboardMenu;
