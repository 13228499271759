/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
    Segment,
    Container,
    Card,
    Message,
} from 'semantic-ui-react';

import DashboardMenu from './dashboardMenu';
import DocumentModal from './DocumentModal';
import DocumentEditModal from './DocumentEditModal';
import CaseModal from './CaseModal';
import CaseHeader from './CaseHeader';
import DashboardCard from './DashboardCard';
import CopyMoveModal from './CopyMoveModal';

function Dashboard(props) {
    const [open, setOpen] = useState(false);
    const [openCaseModal, setOpenCaseModal] = useState(false);
    const [openCopyMove, setOpenCopyMove] = useState(false);
    const [openEditItem, setOpenEditItem] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [searchFilterText, setSearchFilterText] = useState('');

    useEffect(() => {
        const { onLoadAll, uid, match } = props;
        const { params: { id } } = match;
        onLoadAll({ uid, caseId: id });
    // eslint-disable-next-line react/destructuring-assignment
    }, [props.uid]);

    const addOne = () => setOpen(true);

    const saveItem = (item) => {
        const { onSaveItem, uid, match } = props;
        const { params: { id } } = match;
        onSaveItem({ item, uid, caseId: id });
    };

    const editSaveItem = (item) => {
        const { onEditSaveItem, uid, match } = props;
        const { params: { id } } = match;
        onEditSaveItem({ item, uid, caseId: id });
    };

    const saveCase = (cas) => {
        const { onSaveCase, uid } = props;
        onSaveCase({
            cas,
            uid,
        });
    };

    const addCase = () => {
        // open new case modal
        setOpenCaseModal(true);
    };

    const markAsDone = ({ currentTarget }) => {
        const { onMarkAsDone, uid, match } = props;
        const { params: { id } } = match;
        const itemId = currentTarget.getAttribute('data');
        onMarkAsDone && onMarkAsDone({ id: itemId, uid, caseId: id });
    };

    const deleteItem = ({ currentTarget }) => {
        const { onDeleteItem, uid, match } = props;
        const { params: { id } } = match;
        const itemId = currentTarget.getAttribute('data');
        onDeleteItem && onDeleteItem({ id: itemId, uid, caseId: id });
    };

    const closeCase = (id) => {
        const { onCloseCase, uid, history } = props;
        const callback = () => history.push('/dashboard');
        onCloseCase({ id, uid, callback });
    };

    const copyMove = ({ currentTarget }) => {
        const itemId = currentTarget.getAttribute('data');
        setCurrentItem(itemId);
        setOpenCopyMove(true);
    };

    const copyMoveSave = (id, caseId, operation) => {
        const { onCopyMoveSave, uid } = props;
        const currentCaseId = props?.match?.params?.id;
        const callback = () => setOpenCopyMove(false);
        onCopyMoveSave && onCopyMoveSave({
            id, currentCaseId, uid, caseId, callback, operation,
        });
    };

    const onEdit = ({ currentTarget }) => {
        const itemId = currentTarget.getAttribute('data');
        setCurrentItem(itemId);
        setOpenEditItem(true);
    };


    const searchFilter = (dashId) => {
        const { title, details } = dashItems[dashId];
        return title.toLowerCase().includes(searchFilterText.toLowerCase()) | details.toLowerCase().includes(searchFilterText.toLowerCase())
    }

    const {
        dashItems,
        cases,
        match: { params: { id } },
    } = props;

    return (
        <Container>
            <DashboardMenu
                addCase={addCase}
                addOne={addOne}
                cases={cases}
                onFilter={setSearchFilterText}
            />
            <Segment attached="bottom">
                <CaseHeader
                    id={id}
                    details={cases[id]}
                    onCaseClose={closeCase}
                />
                {Object.keys(dashItems || {}).length === 0
                    && (
                        <Message warning style={{ marginBottom: '25px' }}>
                            <Message.Header>Dashboard Empty</Message.Header>
                            <p>
                                This is where your items will show up once you start creating...
                            </p>
                        </Message>
                    )}
                <Card.Group>
                    {
                        Object.keys(dashItems || {}).filter(searchFilter).map((k) => (
                            <DashboardCard
                                key={k}
                                dashId={k}
                                dashItem={dashItems[k]}
                                onMarkAsDone={markAsDone}
                                onDeleteItem={deleteItem}
                                onCopyMove={copyMove}
                                onEdit={onEdit}
                            />
                        ))
                    }
                </Card.Group>
            </Segment>
            <DocumentModal
                setOpen={setOpen}
                open={open}
                onSave={saveItem}
            />
            { currentItem
            && (
                <DocumentEditModal
                    open={openEditItem}
                    setOpen={setOpenEditItem}
                    item={{ ...dashItems[currentItem], id: currentItem }}
                    onSave={editSaveItem}
                />
            )}
            <CaseModal
                setOpen={setOpenCaseModal}
                open={openCaseModal}
                onSave={saveCase}
            />
            <CopyMoveModal
                setOpen={setOpenCopyMove}
                open={openCopyMove}
                onSave={copyMoveSave}
                itemId={currentItem}
                cases={Object.keys(cases).map((k) => ({ key: k, value: k, text: cases[k].title }))}
            />
        </Container>
    );
}

export default Dashboard;
