/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import './App.css';
import {
    Message,
    Container,
    Icon,
    Button,
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import {
    HashRouter,
    Route,
    withRouter,
    Redirect,
} from 'react-router-dom';
import firebase from 'firebase';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { DB_CONFIG, SERVER } from './config/config';

// custom components
import Header from './components/Header';
import Login from './components/Login/loginContainer';
import Register from './components/Register/registerContainer';
import Profile from './components/Profile/profileContainer';
import Dashboard from './components/Dashboard/dashboardContainer';
import Archive from './components/Archives/Archive';

// redux components
import createReduxStore from './createReduxStore';

function PrivateRoute({
    component: Comp,
    authed,
    uid,
    ...rest
}) {
    return (
        <Route
            {...rest}
            render={(props) => (authed === true
                ? <Comp {...props} uid={uid} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
        />
    );
}

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loggedInUserToken: localStorage.getItem('userToken'),
            verifyMessage: `An e-mail has been sent to your account
            with a link to verify your address.`,
            showEmailButton: true,
        };

        this.store = createReduxStore();

        if (firebase.apps.length === 0) firebase.initializeApp(DB_CONFIG);

        const fbConfig = {
            userProfile: 'users',
        };

        const { store } = this;
        this.rrfProps = {
            firebase,
            config: fbConfig,
            dispatch: store.dispatch,
        };
    }

    componentDidMount() {
        const { authStateHasChanged } = this;
        firebase
            .auth()
            .onAuthStateChanged(authStateHasChanged);
    }

    authStateHasChanged = (user) => {
        const me = this;
        if (!user) {
            me.setState({
                loggedInUserToken: null,
            });
            localStorage.removeItem('userToken');
            return;
        }

        user.getIdToken().then((data) => {
            me.setState({
                loggedInUserToken: data,
                uid: user.uid,
            });
            localStorage.setItem('userToken', data);
        });
    };

    sendEmailVerification = () => {
        const me = this;
        firebase.auth().currentUser.sendEmailVerification().then(() => {
            me.setState({
                verifyMessage: `Thank you, a new verification e-mail has been sent to you, 
                please check your e-mail.`,
                showEmailButton: false,
            });
        });
    };

    render() {
        const LoginWithLocation = withRouter(Login);
        const SignupWithLocation = withRouter(Register);
        const { rrfProps, store } = this;
        const {
            loggedInUserToken,
            uid,
            verifyMessage,
            showEmailButton,
        } = this.state;

        let emailVerified = true;
        if (rrfProps.firebase.auth().currentUser) {
            emailVerified = rrfProps.firebase.auth().currentUser.emailVerified;
        }

        return (
            <Provider store={store}>
                <ReactReduxFirebaseProvider {...rrfProps}>
                    <HashRouter>
                        <div>
                            <Header />
                            { !emailVerified
                            && (
                                <Container>
                                    <br />
                                    <Message warning>
                                        <Message.Header>
                                            <Icon name="warning sign" />
                                            Please verifiy your e-mail address
                                        </Message.Header>
                                        {verifyMessage}
                                        &nbsp;
                                        {
                                            showEmailButton
                                                && (
                                                    <Button
                                                        onClick={this.sendEmailVerification}
                                                        basic
                                                        style={{ borderWidth: '0px', boxShadow: '0px 0px' }}
                                                    >
                                                        resend e-mail?
                                                    </Button>
                                                )
                                        }
                                    </Message>
                                </Container>
                            )}
                            <div style={{ marginTop: '15px' }}>
                                <PrivateRoute
                                    authed={loggedInUserToken != null}
                                    uid={uid}
                                    exact
                                    path="/"
                                    component={Profile}
                                />
                                <PrivateRoute
                                    authed={loggedInUserToken != null}
                                    uid={uid}
                                    path="/archives"
                                    component={Archive}
                                />
                                <PrivateRoute
                                    authed={loggedInUserToken != null}
                                    uid={uid}
                                    path="/dashboard/:id?"
                                    component={Dashboard}
                                />
                                {
                                    SERVER.allowNewUser && <Route exact path="/signup" component={SignupWithLocation} />
                                }
                                <Route
                                    path="/login"
                                    component={
                                        // eslint-disable-next-line max-len
                                        () => <LoginWithLocation loggedInUserToken={loggedInUserToken} />
                                    }
                                />
                            </div>
                        </div>
                    </HashRouter>
                </ReactReduxFirebaseProvider>
            </Provider>
        );
    }
}

export default App;
