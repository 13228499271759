import { TYPES } from './dashboardActions';

const initialState = {
    loading: false,
    error: null,
    items: {},
    cases: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.LOAD_ALL:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case TYPES.LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                items: action.items,
            };
        case TYPES.LOAD_ALL_CASES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                cases: action.cases,
            };
        case TYPES.SAVE_ITEM:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case TYPES.SAVE_ITEM_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                items: {
                    ...state.items,
                    [action.id]: action.item,
                },
            };
        }
        case TYPES.EDIT_SAVE_ITEM_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                items: {
                    ...state.items,
                    [action.id]: action.item,
                },
            };
        }
        case TYPES.SAVE_CASE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case TYPES.SAVE_CASE_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                cases: {
                    ...state.cases,
                    [action.id]: action.cas,
                },
            };
        }
        case TYPES.SAVE_ITEM_ERROR:
            return { ...state, loading: false, error: action.error };
        case TYPES.MARK_COMPLETE:
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.id]: {
                        ...state.items[action.id],
                        isDone: true,
                    },
                },
            };
        case TYPES.DELETE_ITEM: {
            const items = {
                ...state.items,
            };
            delete items[action.id];
            return {
                ...state,
                items,
            };
        }
        case TYPES.CLOSE_CASE_SUCCESS: {
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.id]: {
                        ...state.cases[action.id],
                        status: 'closed',
                    },
                },
            };
        }
        case TYPES.OPEN_CASE: {
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.id]: {
                        ...state.cases[action.id],
                        status: 'open',
                    },
                },
            };
        }
        case TYPES.COPY_MOVE_ITEM: {
            const {
                id, operation,
            } = action;
            const newItems = { ...state.items };
            if (operation === 'Move') {
                delete newItems[id];
            }
            return {
                ...state,
                items: newItems,
            };
        }
        case TYPES.DELETE_CASE: {
            const { id } = action;
            return {
                ...state,
                cases: Object.keys(state.cases)
                    .filter((key) => key !== id)
                    .map((key) => state.cases[key]),
            };
        }
        default:
            return { ...state };
    }
};

export default reducer;
