/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
import {
    Image,
    Icon,
    Container,
} from 'semantic-ui-react';
import React from 'react';

function DashboardFile({ file: { fileType, fileUrl } }) {
    if (fileType.toString().indexOf('image') > -1) {
        return (
            <Image
                as="img"
                src={fileUrl}
                wrapped
            />
        );
    }

    if (fileType === 'application/vnd.ms-excel') {
        return (
            <Container>
                <Icon size="big" name="file excel outline" />
            </Container>
        );
    }

    if (fileType === 'application/pdf') {
        return (
            <Container>
                <Icon size="big" name="file pdf outline" />
            </Container>
        );
    }

    // default
    return (
        <Container>
            <Icon size="big" name="file text outline" />
        </Container>
    );
}

export default DashboardFile;
