/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-expressions */
import {
    Form,
    Button,
} from 'semantic-ui-react';
import React, { useRef } from 'react';

function DocumentItem(props) {
    const fileInputRef = useRef();

    function textChange(e) {
        const { currentTarget } = e;
        const { onChange } = props;
        onChange && onChange(currentTarget.value);
    }

    function fileChange() {
        const { addFiles } = props;
        const files = Object
            .keys(fileInputRef.current.files)
            .map((key) => fileInputRef.current.files[key]);
        addFiles(files);
    }

    // we will render different input type based on the itemType selection
    if (props.itemType === 'no') {
        return (
            <Form.TextArea
                label="Details"
                placeholder="Add your details..."
                onChange={textChange}
                value={props.value ?? props.value}
            />
        );
    }
    if (props.itemType === 'ur') {
        return (
            <Form.Input
                label="url"
                placeholder="http://"
                onChange={textChange}
                value={props.value ?? props.value}
            />
        );
    }
    if (props.itemType === 'im') {
        return (
            <>
                <Button
                    content="Choose File"
                    labelPosition="left"
                    icon="file"
                    onClick={() => fileInputRef.current.click()}
                />
                <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    onChange={fileChange}
                />
            </>
        );
    }
}

export default DocumentItem;
