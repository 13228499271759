import React, { PureComponent } from 'react';
import { Button, Confirm } from 'semantic-ui-react';

class ConfirmDialog extends PureComponent {
    constructor() {
        super();
        this.state = { open: false };
    }

    show = () => this.setState({ open: true });

    handleConfirm = () => {
        const { onConfirm } = this.props;
        this.setState({ open: false });
        onConfirm();
    }

    handleCancel = () => {
        const { onCancel } = this.props;
        this.setState({ open: false });
        // eslint-disable-next-line no-unused-expressions
        onCancel && onCancel();
    }

    render() {
        const { children, message } = this.props;
        const { open } = this.state;
        return (
            <>
                <Button basic onClick={this.show}>{children}</Button>
                <Confirm
                    open={open}
                    onCancel={this.handleCancel}
                    onConfirm={this.handleConfirm}
                    content={message}
                />
            </>
        );
    }
}

export default ConfirmDialog;
