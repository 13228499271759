import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Icon,
    Form,
} from 'semantic-ui-react';

import DocumentItem from './DocumentItem';

const DocumentEditModal = ({
    open,
    setOpen,
    item,
    onSave,
}) => {
    const [itemDetails, setItemDetails] = useState({ ...item });
    useEffect(() => { setItemDetails(item); }, [item]);

    const detailsChange = (details) => {
        setItemDetails({
            ...itemDetails,
            details,
        });
    };

    const addFiles = (files) => setItemDetails({
        ...itemDetails,
        itemFiles: files,
    });

    const titleChange = ({ currentTarget }) => {
        setItemDetails({
            ...itemDetails,
            title: currentTarget.value,
        });
    };

    const onSaveHandler = () => {
        onSave(itemDetails);
        setOpen(false);
    };

    return (
        <Modal open={open}>
            <Modal.Header>
                <Icon name="plus square outline" />
                Edit Item
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Input
                                fluid
                                label="Title"
                                value={itemDetails.title}
                                onChange={titleChange}
                            />
                        </Form.Group>
                        <DocumentItem
                            itemType={itemDetails.itemType}
                            onChange={detailsChange}
                            addFiles={addFiles}
                            value={itemDetails.details}
                        />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    color="black"
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    content="Save"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={onSaveHandler}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
};

export default DocumentEditModal;
