import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { Icon } from 'semantic-ui-react';

class Login extends Component {
    constructor(props) {
        super(props);
        const { history, loggedInUserToken } = this.props;
        this.state = {
            username: '',
            password: '',
        };

        // if logged in, send to home route
        if (loggedInUserToken !== null) {
            history.push('/');
        }
    }

    changeText = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    signUp = () => {
        const { history } = this.props;
        history.push('/signup');
    }

    authUser = () => {
        const { username, password } = this.state;
        const { onSignIn } = this.props;
        onSignIn({
            username,
            password,
        });
    }

    formPreventDefault = (e) => {
        e.preventDefault();
        this.authUser();
    }

    render() {
        const { error, isLoading } = this.props;
        const { username, password } = this.state;
        return (
            <div>
                <div className="ui container login">
                    <form className="ui form attached fluid login-form" onSubmit={this.formPreventDefault}>
                        <h1 className="ui dividing header">
                            arvada.io
                        </h1>
                        <div className="field">
                            <label>Username</label>
                            <input type="text" name="username" placeholder="Username" value={username} onChange={this.changeText} />
                        </div>
                        <div className="field">
                            <label>Password</label>
                            <input type="password" name="password" placeholder="Password" value={password} onChange={this.changeText} />
                        </div>
                        <button type="submit" id="loginButton" className="fluid ui basic button">
                            <Icon name="lock" />
                            Login
                        </button>
                        <button type="button" id="signupButton" className="fluid ui button" onClick={this.signUp}>
                            <Icon name="user" />
                            Sign-up
                        </button>
                        <div className={`ui inverted dimmer ${isLoading === true ? 'active' : ''}`}>
                            <div className="ui text loader">Signing-in</div>
                        </div>
                    </form>
                    {
                        error
                            ? (
                                <div className="ui fluid yellow message">
                                    <div className="header">
                                        <Icon name="exclamation triangle" />
                                        {' '}
                                        {error}
                                    </div>
                                </div>
                            )
                            : ''
                    }
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    onSignIn: PropTypes.func.isRequired,
    error: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

export default Login;
