const TYPES = {
    SAVE_ITEM: 'SAVE_ITEM',
    SAVE_ITEM_SUCCESS: 'SAVE_ITEM_SUCCESS',
    SAVE_ITEM_ERROR: 'SAVE_ITEM_ERROR',

    EDIT_SAVE_ITEM: 'EDIT_SAVE_ITEM',
    EDIT_SAVE_ITEM_SUCCESS: 'EDIT_SAVE_ITEM_SUCCESS',
    EDIT_SAVE_ITEM_ERROR: 'EDIT_SAVE_ITEM_ERROR',

    LOAD_ALL: 'LOAD_ALL',
    LOAD_ALL_SUCCESS: 'LOAD_ALL_SUCCESS',
    LOAD_ALL_CASES_SUCCESS: 'LOAD_ALL_CASES_SUCCESS',
    LOAD_ALL_ERROR: 'LOAD_ALL_ERROR',

    SAVE_CASE: 'SAVE_CASE',
    SAVE_CASE_SUCCESS: 'SAVE_CASE_SUCCESS',
    SAVE_CASE_ERROR: 'SAVE_CASE_ERROR',
    OPEN_CASE: 'OPEN_CASE',
    CLOSE_CASE: 'CLOSE_CASE',
    CLOSE_CASE_SUCCESS: 'CLOSE_CASE_SUCCESS',
    DELETE_CASE: 'DELETE_CASE',
    DELETE_CASE_SUCCESS: 'DELETE_CASE_SUCCESS',

    MARK_COMPLETE: 'MARK_COMPLETE',
    DELETE_ITEM: 'DELETE_ITEM',
    COPY_MOVE_ITEM: 'COPY_MOVE_ITEM',
};

const editSaveItem = ({ item, uid, caseId }) => ({
    type: TYPES.EDIT_SAVE_ITEM, item, uid, caseId,
});

const saveItem = ({ item, uid, caseId }) => ({
    type: TYPES.SAVE_ITEM, item, uid, caseId,
});
const saveCase = ({ cas, uid }) => ({ type: TYPES.SAVE_CASE, cas, uid });
const loadAll = ({ uid, caseId }) => ({ type: TYPES.LOAD_ALL, uid, caseId });
const selectCase = ({ caseId }) => ({ type: TYPES.SET_ACTIVE, caseId });
const markAsComplete = ({ id, uid, caseId }) => ({
    type: TYPES.MARK_COMPLETE, id, uid, caseId,
});
const deleteItem = ({ id, uid, caseId }) => ({
    type: TYPES.DELETE_ITEM, id, uid, caseId,
});

const closeCase = ({ id, callback }) => ({
    type: TYPES.CLOSE_CASE, id, callback,
});

const openCase = ({ id }) => ({
    type: TYPES.OPEN_CASE, id,
});

const removeCase = ({ id, uid }) => ({
    type: TYPES.DELETE_CASE, id, uid,
});

const copyMove = (args) => ({
    type: TYPES.COPY_MOVE_ITEM, ...args,
});

export {
    TYPES,
    saveItem,
    editSaveItem,
    saveCase,
    closeCase,
    loadAll,
    selectCase,
    markAsComplete,
    deleteItem,
    openCase,
    copyMove,
    removeCase,
};
