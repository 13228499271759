/* Get these settings from your firebase account */
export const DB_CONFIG = {
    apiKey: 'AIzaSyCR9v_pIJtzeXv2yhPjrfeyyxBxs537yFc',
    authDomain: 'strad-c7ed3.firebaseapp.com',
    databaseURL: 'https://strad-c7ed3.firebaseio.com',
    projectId: 'strad-c7ed3',
    storageBucket: 'strad-c7ed3.appspot.com',
    messagingSenderId: '188254471996',
    appId: '1:188254471996:web:26498ebe96bff583e00039',
};

export const SERVER = {
    url: '',
    allowNewUser: true,
};
