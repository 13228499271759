/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react';
import 'react-smart-data-table/dist/react-smart-data-table.css';
import SmartDataTable from 'react-smart-data-table';
import * as actions from '../Dashboard/dashboardActions';
import ArchiveActions from './ArchiveActions';

type Props = {
    onOpenCase: (args:any) => void;
    onCloseCase: (args:any) => void;
    onRemoveCase: (args:any) => void;
    onLoadAll: (args:any) => void;
    uid: string;
    match: any;
    data:any;
}

const Archive:React.FunctionComponent<Props> = (props) => {
    useEffect(() => {
        const { onLoadAll, uid, match } = props;
        const { params: { id } } = match;
        onLoadAll({ uid, caseId: id });
    // eslint-disable-next-line react/destructuring-assignment
    }, [props.uid]);

    const handleRowClick = (action:string, { id }:any) => {
        const {
            onOpenCase, onCloseCase, onRemoveCase,
        } = props;
        if (action === 'open') onOpenCase({ id });
        if (action === 'close') onCloseCase({ id, callback: () => {} });
        if (action === 'remove') onRemoveCase({ id, callback: () => {} });
    };

    const { data } = props;
    const columns = [
        'title',
        'status',
        'caseOpened',
        'lastUpdated',
        'tableActions',
    ];
    const headers = {
        tableActions: {
            text: 'Actions',
            invisible: false,
            sortable: false,
            filterable: false,
            transform: (value:any, index:number, row:any) => (
                <ArchiveActions
                    onRowClick={handleRowClick}
                    row={row}
                    value={value}
                    index={index}
                />
            ),
        },
        caseOpened: {
            text: 'Case Opened',
            transform: (value:any) => (
                <span>{new Date(value).toDateString()}</span>
            ),
        },
        lastUpdated: {
            text: 'Last Updated',
            transform: (value:any) => (
                <span>{new Date(value).toDateString()}</span>
            ),
        },
    };

    return (
        <Container>
            <SmartDataTable
                headers={headers}
                orderedHeaders={columns}
                data={data}
                name="archive-table"
                className="ui compact selectable table"
                sortable
                // onRowClick={handleRowClick}
                perPage={15}
                hideUnordered
            />
        </Container>
    );
};

const mapStateToProps = (state:any) => {
    const cases = state?.dashboard?.cases;
    const data = Object.keys(cases || {}).reduce((accum:Array<any> = [], key) => {
        accum.push({
            id: key,
            ...cases[key],
        });
        return accum;
    }, []);
    return {
        data,
        uid: state?.firebase?.auth?.uid,
    };
};

const mapDispatchToProps = (dispatch:any) => ({
    onLoadAll: (args:any) => dispatch(actions.loadAll(args)),
    onOpenCase: (args:any) => dispatch(actions.openCase(args)),
    onRemoveCase: (args:any) => dispatch(actions.removeCase(args)),
    onCloseCase: (args:any) => dispatch(actions.closeCase(args)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Archive);
