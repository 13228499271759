import { compose } from 'redux';
import { connect } from 'react-redux';
import Header from './Header';
import { getUserProfile } from '../../rootSelector';

const mapStateToProps = (state) => {
    const profile = getUserProfile(state);
    return {
        profileData: profile,
    };
};

export default compose(
    connect(
        mapStateToProps,
        null,
    ),
)(Header);
