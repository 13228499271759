/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import {
    all,
    put,
    takeLatest,
    takeEvery,
} from 'redux-saga/effects';
import firebase from 'firebase';
import { TYPES } from './dashboardActions';

export function* saveItem(args) {
    try {
        const storageRef = firebase.storage().ref();
        const {
            uid, item, caseId,
        } = args;

        // get files from object
        const { itemFiles } = item;

        // remove files before saving to database
        delete item.itemFiles;
        const path = caseId ? `/cases/${uid}/${caseId}/dash-items` : `/dash-items/${uid}`;
        const response = yield firebase.database()
            .ref(path)
            .push({
                ...item,
                isDone: false,
            });

        let returnItem = {
            ...item,
        };

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < itemFiles.length; i++) {
            const file = itemFiles[i];
            const fileName = Date.now();
            const uploadTask = yield storageRef
                .child(`dash-items/${uid}/${response.key}/${fileName}`)
                .put(file, { contentType: file.type });
            const fileUrl = yield uploadTask.ref.getDownloadURL();
            const fileToUpload = { fileUrl, fileType: file.type, fileName };
            const fileResponse = yield firebase.database()
                .ref(`${path}/${response.key}/files`)
                .push(fileToUpload);
            returnItem = {
                ...returnItem,
                files: {
                    ...returnItem.files,
                    [fileResponse.key]: fileToUpload,
                },
            };
        }

        yield put({
            type: TYPES.SAVE_ITEM_SUCCESS,
            item: returnItem,
            id: response.key,
        });
    } catch (error) {
        yield put({ type: TYPES.SAVE_ITEM_ERROR, error });
    }
}

export function* updateItem(args) {
    try {
        const {
            uid, item, caseId,
        } = args;

        // remove files before saving to database
        delete item.itemFiles;
        const path = caseId ? `/cases/${uid}/${caseId}/dash-items` : `/dash-items/${uid}`;
        const response = yield firebase.database()
            .ref(path)
            .update({
                ...item,
                lastUpdated: Date.now(),
            });

        yield put({
            type: TYPES.SAVE_ITEM_SUCCESS,
            item,
            id: response.key,
        });
    } catch (error) {
        yield put({ type: TYPES.SAVE_ITEM_ERROR, error });
    }
}

export function* saveCase(args) {
    try {
        const { uid, cas } = args;
        const caseOpened = Date.now();
        const caseObj = {
            ...cas,
            caseOpened,
            status: 'open',
        };
        const response = yield firebase.database().ref(`/cases/${uid}`).push(caseObj);
        yield put({
            type: TYPES.SAVE_CASE_SUCCESS,
            cas: caseObj,
            id: response.key,
        });
    } catch (error) {
        yield put({ type: TYPES.SAVE_ITEM_ERROR, error });
    }
}

export function* loadItems(args) {
    try {
        const { uid, caseId } = args;
        const path = caseId ? `/cases/${uid}/${caseId}/dash-items` : `/dash-items/${uid}`;
        const snap = yield firebase.database().ref(path).once('value');
        const val = yield snap.val();
        yield put({
            type: TYPES.LOAD_ALL_SUCCESS,
            items: val,
        });
    } catch (error) {
        yield put({ type: TYPES.LOAD_ALL_ERROR, error });
    }
}

export function* loadCases(args) {
    try {
        const { uid } = args;
        const snap = yield firebase.database().ref(`/cases/${uid}`).once('value');
        const val = yield snap.val();
        yield put({
            type: TYPES.LOAD_ALL_CASES_SUCCESS,
            cases: val,
        });
    } catch (error) {
        yield put({ type: TYPES.LOAD_ALL_ERROR, error });
    }
}

export function* markComplete({ id, uid, caseId }) {
    const path = caseId
        ? `/cases/${uid}/${caseId}/dash-items/${id}`
        : `/dash-items/${uid}/${id}`;
    yield firebase.database().ref(path).update({ isDone: true });
}

export function* deleteItem({ id, uid, caseId }) {
    const storageRef = firebase.storage().ref();
    const path = caseId
        ? `/cases/${uid}/${caseId}/dash-items/${id}`
        : `/dash-items/${uid}/${id}`;

    const snap = yield firebase.database().ref(`${path}/files`).once('value');
    const record = yield snap.val();
    yield Object.keys(record || {}).map((key) => {
        if (record[key] && record[key].fileName) {
            return storageRef.child(`dash-items/${uid}/${id}/${record[key].fileName}`).delete();
        }
        return null;
    });
    yield firebase.database().ref(path).remove();
}

export function* closeCase({ id, callback }) {
    const { uid } = firebase.auth().currentUser;
    yield firebase.database()
        .ref(`/cases/${uid}/${id}`)
        .update({
            status: 'closed',
            lastUpdated: Date.now(),
        });
    yield put({ type: TYPES.CLOSE_CASE_SUCCESS, id });
    callback && callback();
}

export function* openCase({ id }) {
    const { uid } = firebase.auth().currentUser;
    yield firebase.database()
        .ref(`/cases/${uid}/${id}`)
        .update({
            status: 'open',
            lastUpdated: Date.now(),
        });
}

export function* copySave({
    id, uid, currentCaseId, caseId, callback, operation,
}) {
    const path = currentCaseId
        ? `/cases/${uid}/${currentCaseId}/dash-items/${id}`
        : `/dash-items/${uid}/${id}`;

    // get target item to copy/move
    const snapshot = yield firebase.database().ref(path).once('value');
    const item = yield snapshot.val();
    // put the record under the chosen case
    yield firebase.database().ref(`/cases/${uid}/${caseId}/dash-items`).push(item);
    // delete the original on move operation
    if (operation === 'Move') yield firebase.database().ref(path).remove();
    callback && callback();
}

export function* saveEditItem({ item, uid, caseId }) {
    const { id } = item;
    const newItem = { ...item };

    // item path could be under a case or a default dashboard item
    const path = caseId
        ? `/cases/${uid}/${caseId}/dash-items/${id}`
        : `/dash-items/${uid}/${id}`;

    if (item.itemFiles && item.itemFiles.length === 1) {
        const { itemFiles } = item;
        const storageRef = firebase.storage().ref();
        yield storageRef.child(`dash-items/${uid}/${id}/${item.files[Object.keys(item.files)[0]].fileName}`).delete();

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < itemFiles.length; i++) {
            const file = itemFiles[i];
            const fileName = Date.now();
            const uploadTask = yield storageRef
                .child(`dash-items/${uid}/${id}/${fileName}`)
                .put(file, { contentType: file.type });

            const fileUrl = yield uploadTask.ref.getDownloadURL();
            const fileToUpload = { fileUrl, fileType: file.type, fileName };
            const fileResponse = yield firebase.database()
                .ref(`${path}/${id}/files`)
                .push(fileToUpload);

            newItem.files = {
                // ...newItem.files, (when we allow multiples)
                [fileResponse.key]: fileToUpload,
            };
        }
    }

    delete newItem.id;
    delete newItem.itemFiles;

    const result = yield firebase.database().ref(path).set(newItem);

    yield put({ type: TYPES.EDIT_SAVE_ITEM_SUCCESS, item: newItem, id });
}

export default function* saga() {
    yield all([
        takeEvery(TYPES.SAVE_ITEM, saveItem),
        takeEvery(TYPES.SAVE_CASE, saveCase),
        takeLatest(TYPES.LOAD_ALL, loadItems),
        takeLatest(TYPES.LOAD_ALL, loadCases),
        takeEvery(TYPES.MARK_COMPLETE, markComplete),
        takeEvery(TYPES.DELETE_ITEM, deleteItem),
        takeEvery(TYPES.CLOSE_CASE, closeCase),
        takeEvery(TYPES.OPEN_CASE, openCase),
        takeEvery(TYPES.COPY_MOVE_ITEM, copySave),
        takeEvery(TYPES.EDIT_SAVE_ITEM, saveEditItem),
    ]);
}
